import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "whitelabel",
      "style": {
        "position": "relative"
      }
    }}>{`Whitelabel`}</h2>
    <p>{`As discussed in `}<a parentName="p" {...{
        "href": "/foundations"
      }}>{`Foundations`}</a>{`, values that make up the identity of
the brand, are stored in JSON values. These foundations are then converted to
platform-specific tokens that can subsequently be used in components.`}</p>
    <p>{`While a great deal of these properties contribute to the identity of the brand,
a number of them don't have a clear branding value or are not regarded as vital
to a brand's branding.`}</p>
    <p>{`To not force a brand to define all needed variables, the concept of Whitelabel
was born. Whitelabel is a brandless brand that disposes of all the values. These
values are a set of sensible defaults that are a great starting point for most
brands.`}</p>
    <h2 {...{
      "id": "brand-extension",
      "style": {
        "position": "relative"
      }
    }}>{`Brand extension`}</h2>
    <p>{`As said, Whitelabel is the starting point for all brands. To define a brand,
it's sufficient to provide the values the brand wants to overwrite. While a
brand has the freedom to overwrite every single value, brands can also provide
just a single value. The values they provide, are then merged with the values
defined in Whitelabel. The result is the total set of tokens, which is a mix of
brand-specific values completed with the sensible defaults provided by
Whitelabel.`}</p>
    <p>{`This makes onboarding a new brand a mater of gathering the needed branded
variables, running the assemly and out comes a fully functional brand. Brands
can always start by providing a bare minimun (e.g. a primary color and a primary
font), which will already result in usable branded components. Over time, more
brand-specific overrides can be added, to further support their branding
efforts.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d7c101d10d67fcbf621076d0758d6ef2/69ac7/multibranding.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC1UlEQVQoz2VTS2gTQRieTXe3u3knzbMPS9oS+1JqWypFUVusra1STZpmd5PsZtskfSVpk1RErCJFxYPizYsHT4KgoAfBi6IgKFjwqCB4EdSLbyk+2/HfiWl9DMzsLN/833z///2DUo2Tzu2Vac+F7nEeT4foyzvGUHEskvXQ5gkL4L7e6nTF0pDMY1xEz3YlyPfitnEu7p/ybPVkrFgVDKjbm2mx6HN+rIYpmOzrUIQqhiyQdWmf7H4vSE34Uh+Lx8LMR1Ei+MNBmeAPBmXjvQGlA4+H7Tg5akad7oyvjM37EZrn8WyQfh6I/iZECGMHOteVMIsNUzWtjmzFsiQxODVK8DOdSXLm6i6VH6qdaay3zXof74/ZUJcnU8eW51tQWaEcp0foF8HIOmEhQMEFxuu9qqvdlSlfjYkMjgsEv7k7Ts7c7Vf4mebJVp91tuqdIFnQFlfWr2PzXkIwMUqXbi6NsY2TFkQXTNr+W1SkX4Yif+FH21KGKvNcM0LnGZwL8kQhpFxJMQX76c6kCWdHmM+Q2o8oqEmEmVehiPetINWDMudKTLRjWaA1pUStItCfRMkMZtSDobafMdGHwMENQOhC6EjZs4NRHg5ZIND0JSKaoMj2xfaky2mcc13rUTm4gIMgI5ZF8/co4ImwFUxxu01zNZucWQNkaNNMadAUkpTjAo3+GY+GZAahYzqCq//jJzuSnMOYa0LoBIfzAT3qqU5XgcOcBn4QJRZS0oFCpOdy4HItCtZNWxE6bNDwZUlkAaNAIbq9RyGEUCajgc8VPZAFM+oAhUhXIAFvwkCoCOsuHx+m7vQr+is7VdLQK8XaEXyhLVVqcMPAhpm6NUKthgyb94FKBs8H6CfDsTVCuICCZtbDtEAPgjKBgUnwW33Ftnl6IMaByoZmR9b5FequSYd0T1HaU4OXwoAZ6wqnQtSNXpUt/a/+ofD+XqVEyGqGanswzPILxH71nY7yKEIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Inheritance",
            "title": "Inheritance",
            "src": "/static/d7c101d10d67fcbf621076d0758d6ef2/8c557/multibranding.png",
            "srcSet": ["/static/d7c101d10d67fcbf621076d0758d6ef2/4edbd/multibranding.png 175w", "/static/d7c101d10d67fcbf621076d0758d6ef2/13ae7/multibranding.png 350w", "/static/d7c101d10d67fcbf621076d0758d6ef2/8c557/multibranding.png 700w", "/static/d7c101d10d67fcbf621076d0758d6ef2/e996b/multibranding.png 1050w", "/static/d7c101d10d67fcbf621076d0758d6ef2/2cefc/multibranding.png 1400w", "/static/d7c101d10d67fcbf621076d0758d6ef2/69ac7/multibranding.png 2519w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      